<template>
  <div class="insurance-company">
    <custom-scrollbar>
      <div class="crm-container">
        <div class="company-edit-wrapper">
          <loading v-if="isCompanyLoading">
            Загрузка программ
          </loading>

          <template v-else>
            <div class="crm-title text-center">
              {{ isEdit ? 'Редактирование' : 'Добавление' }} компании
            </div>

            <div class="crm-form-fields">
              <base-input
                v-model="$v.company.title.$model"
                class="crm-form-field"
                :error="$v.company.title.$error"
                :errors="errorsValidation.title"
                :input-style="{
                  padding: '11px 20px',
                }"
                label="Название страховой компании"
                placeholder="Введите название"
              />

              <base-input
                v-model="$v.company.phoneNumber.$model"
                class="crm-form-field"
                :error="$v.company.phoneNumber.$error"
                :errors="errorsValidation.phoneNumber"
                :input-style="{
                  padding: '11px 20px',
                }"
                label="Телефон горячей линии"
                placeholder="Введите номер"
              />
            </div>

            <div class="d-flex justify-content-between mb-4">
              <div>
                <div>
                  <span class="crm-label">
                    Логотип страховой компании
                  </span>

                  <b-button
                    v-if="!company.logo"
                    variant="outline-primary"
                    :type="$const.PRIMARY_BUTTON"
                    size="sm"
                    @click="openPhotoModal"
                  >
                    Загрузить
                  </b-button>

                  <div
                    v-else
                    class="image-controls"
                  >
                    <img
                      class="img-company"
                      :src="photoUrl"
                    >

                    <b-button
                      variant="outline-primary"
                      :type="$const.PRIMARY_BUTTON"
                      size="sm"
                      @click="openPhotoModal"
                    >
                      Изменить
                    </b-button>
                  </div>
                </div>

                <div
                  v-if="$v.company.logo.$error"
                  class="validation-errors"
                >
                  <span
                    class="validation-error-text"
                  >
                    {{ errorsValidation.logo[0] }}
                  </span>
                </div>
              </div>

              <div>
                <div class="font-weight-bold mb-2">
                  Ответственные
                </div>

                <div
                  v-for="(responsible, index) in responsibles"
                  :key="index"
                  class="d-flex mb-2"
                >
                  <b-form-select
                    v-model="responsible.id"
                    :options="availableResponsibles"
                    text-field="displayName"
                    value-field="id"
                    size="sm"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                      >
                        Выберите ответственного за подпрограмму
                      </b-form-select-option>
                    </template>
                  </b-form-select>

                  <b-button
                    size="sm"
                    variant="danger"
                    :type="$const.PRIMARY_BUTTON"
                    class="ml-2"
                    @click="responsibles.splice(index, 1)"
                  >
                    <b-icon icon="x" />
                  </b-button>
                </div>

                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  class="mt-2"
                  @click="responsibles.push({id: null})"
                >
                  Добавить ответственного
                </b-button>

                <!-- <div
                  v-if="$v.subprogram.activationType.$error"
                  class="mt-2 text-danger"
                >
                  {{ errorsValidation.activationType[0] }}
                </div> -->
              </div>
            </div>

            <div class="d-flex w-100 justify-content-between">
              <div class="w-25" />
              <b-button-group>
                <b-button
                  variant="outline-primary"
                  :type="$const.PRIMARY_BUTTON"
                  :disabled="isSaving"
                  @click="onCancel"
                >
                  Отменить
                </b-button>

                <b-button
                  variant="primary"
                  :type="$const.PRIMARY_BUTTON"
                  :disabled="isSaving || !company.logo"
                  class="ml-3"
                  @click="onSave"
                >
                  Сохранить
                </b-button>
              </b-button-group>

              <div class="w-25 d-flex justify-content-end">
                <b-button
                  v-if="isEdit"
                  v-b-tooltip.hover
                  :title="company.isHidden ? 'Убрать из скрытых' : 'Скрыть страховую компанию'"
                  :variant="company.isHidden ? 'outline-primary' : 'outline-danger'"
                  :type="$const.PRIMARY_BUTTON"
                  @click="openAgreeModal"
                >
                  {{ company.isHidden ? 'Убрать из скрытых' : 'Скрыть' }}
                </b-button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </custom-scrollbar>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import { showErrorMessageGetUsers, showValidationErrorMessage } from '@/helpers/messages';
import { ROLES } from '@/helpers/roles';
import Loading from '@/components/Loading';
import { accountService } from '@/services';

import { BaseInput } from '@/components/base';
import { APP_HOST } from '@/app.config';

export default {
  name: 'InsuranceCompanyEdit',
  components: {
    BaseInput,
    Loading,
  },
  mixins: [validationMixin],
  validations() {
    return {
      company: {
        title: {
          required,
        },
        phoneNumber: {
          required,
        },
        logo: {
          // required,
        },
      },
    };
  },
  data() {
    return {
      company: {
        id: null,
        title: null,
        phoneNumber: null,
        logo: null,
      },
      isCompanyLoading: false,
      isSaving: false,
      roles: [],
      availableResponsibles: [],
      responsibles: [],
    };
  },
  computed: {
    errorsValidation() {
      const errors = {};

      errors.title = [];
      if (!this.$v.company.title.required) {
        errors.title.push('Поле не может быть пустым');
      }

      errors.phoneNumber = [];
      if (!this.$v.company.phoneNumber.required) {
        errors.phoneNumber.push('Поле не может быть пустым');
      }

      errors.logo = [];
      if (!this.$v.company.logo.required) {
        errors.logo.push('Необходимо добавить фото');
      }

      return errors;
    },
    rolePolisOperator() {
      if (!this.roles || !this.roles.length) return;

      return this.roles.find((item) => item.name === ROLES.POLIS_OPERATOR);
    },
    rolePolisOperatorDop() {
      if (!this.roles || !this.roles.length) return;

      return this.roles.find((item) => item.name === ROLES.POLIS_OPERATOR_DOP);
    },
    isEdit() {
      return !!this.$route.params.company;
    },
    companyId() {
      return this.$route.params.company;
    },
    photoUrl() {
      if (this.company.logo !== '00000000-0000-0000-0000-000000000000' && this.company.logo !== null) {
        // eslint-disable-next-line max-len
        return `${APP_HOST === '/api/' ? 'https://crm.doctis.ru:6004/api/' : APP_HOST}File/${this.company.logo}`;
      }

      return null;
    },
  },
  async created() {
    await this.onCreate();
    this.roles = await this.$store.dispatch(this.$types.ROLES_FETCH);
    this.availableResponsibles = await this.fetchResponsibles();
  },
  methods: {
    async fetchResponsibles() {
      const data = {
        take: 99999,
        roleIds: [this.rolePolisOperator?.id, this.rolePolisOperatorDop?.id],
      };
      try {
        const responsibles = await accountService.getUsers(data);
        return responsibles;
      } catch (e) {
        console.warn(e);
        showErrorMessageGetUsers();
      }
    },
    async onCreate() {
      if (this.companyId) {
        this.isCompanyLoading = true;
        const { company } = await this.$store.dispatch(this.$types.COMPANY_FETCH, this.companyId);
        this.company = company;
        if (company.responsibles && Array.isArray(company.responsibles) && company.responsibles.length) {
          this.responsibles = company.responsibles.map((item) => ({ id: item.id }));
        }
        delete this.company.responsibles;
        this.isCompanyLoading = false;
      }
    },
    openPhotoModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'InsuranceCompanyPhotoModal',
        props: {
          photoUrlProp: this.photoUrl,
          imageHeight: 80,
          imageWidth: 80,
          saveCallback: (guid) => { this.$v.company.logo.$model = guid || ''; },
        },
      });
    },
    onCancel() {
      this.$router.push('/insurance-companies');
    },
    async onSave() {
      this.$v.$touch();

      if (this.$v.$error) {
        showValidationErrorMessage();

        return;
      }

      try {
        const responsibleIds = this.responsibles.filter((item) => item.id !== null).map((item) => item.id);
        const requestData = {
          ...this.company,
          responsibleIds,
        };

        if (this.company.id) {
          await this.$store.dispatch(this.$types.COMPANY_UPDATE, requestData);
        } else {
          await this.$store.dispatch(this.$types.COMPANY_CREATE, requestData);
        }
        this.$router.push('/insurance-companies');
      } finally {
        this.isSaving = false;
      }
    },
    async hideOrShowCompany() {
      await this.$store.dispatch(this.$types.COMPANY_HIDE_OR_SHOW, {
        params: { companyId: this.companyId },
      });

      await this.onCreate();
    },
    openAgreeModal() {
      this.$store.commit(
        this.$types.OPEN_MODAL,
        {
          name: 'AgreeModal',
          props: { cb: this.hideOrShowCompany },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.insurance-company {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.company-edit-wrapper {
  width: 100%;
  margin-top: 20px;
  background: #FFFFFF;
  // box-shadow: 0px 1px 2px #C4C4C4;
  border-radius: 5px;
  border: 1px solid #dee2e6;
  padding: 30px;
}
.crm-title {
  @extend .page-title;

  margin-bottom: 30px;
}
.crm-form-fields {
  position: relative;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.img-company {
  width: 40px;
  height: 40px;
  margin-right: 20px;
}
.image-controls {
  display: flex;
}
// .button-transparent {
//   background-color: transparent;
//   padding: 0;
//   border: none;
// }
</style>
